/* eslint camelcase: "off" */
import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getReportCodeService,
  getAllReportCodeService,
  updateReportCodeService,
  getReportCodesPaginatorService,
  countReportCodesService
} from '../../services/report-codes.service'
import {
  StyledCSVLink,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr,
  InputXs,
  SelectXs
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Clacom } from './interface'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { headerCsv } from './headerExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: Clacom = {
  active: '',
  bu: '',
  country: '',
  destinationCapacity: '',
  destinationData: '',
  destinationDescription: '',
  id: '',
  originCapacity: '',
  originData: '',
  originDescription: '',
  originDetail: '',
  retail: ''
}

const title = 'Códigos de Reporte'

export const ReportCodePage = () => {
  const [reportCodes, setReportCodes] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [reportCodesCsv, setReportCodesCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [codeRimFilter, setCodeRimFilter] = useState('')
  const [codeRimDetailFilter, setCodeRimDetailFilter] = useState('')
  const [codeRimDescFilter, setCodeRimDescFilter] = useState('')
  const [retailFilter, setRetailFilter] = useState('')
  const [accCountFilter, setAccCountFilter] = useState('')
  const [accCountDescFilter, setAccCountDescFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastItem = currentPage * 10
    const indexOfFirstItem = indexOfLastItem - 10
    const dataPaginated = processData.slice(indexOfFirstItem, indexOfLastItem)
    setReportCodes(dataPaginated)
  }

  const getReportCodes = async () => {
    try {
      const resp = await getAllReportCodeService()
      const { data, status } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      // Determinar si la simulación está habilitada
      const simulateProfile = process.env.REACT_APP_SIMULATE_PROFILE === 'true'
      const userProfile = process.env.REACT_APP_USER_PROFILE

      if (simulateProfile && userProfile) {
        const localProfile = JSON.parse(userProfile)
        setProfile(localProfile)
      } else {
        const resp = await getProfileUserService()
        const { status, data } = resp
        if (status === 200) {
          setProfile(data)
        }
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countReportCodes = async () => {
    try {
      const resp = await countReportCodesService()
      const { status, data } = resp
      if (status === 200) {
        setReportCodesCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countReportCodes()
  }, [])

  useEffect(() => {
    getReportCodes()
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(codeRimFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [codeRimFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originDetail).toLowerCase().includes(codeRimDetailFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [codeRimDetailFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originDescription).toLowerCase().includes(codeRimDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [codeRimDescFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.retail).toLowerCase().includes(retailFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [retailFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(accCountFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [accCountFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationDescription).toLowerCase().includes(accCountDescFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [accCountDescFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/report-code-form')
  }

  const handleModify = (id: string) => {
    navigate(`/report-code-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getReportCodeService(id)
      const { all_fields_json } = resp.data
      if (!all_fields_json.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          all_fields_json.active = false
          updateReportCodeService(id, all_fields_json)
          Swal.fire('Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getReportCodes())
        }
      })
    } catch (error) {
      throw new Error(`error ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Código Mayor RIM' onChange={(e) => setCodeRimFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Código Detalle RIM' onChange={(e) => setCodeRimDetailFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Descripción RIM' onChange={(e) => setCodeRimDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='SR o NSR' onChange={(e) => setRetailFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Cuenta Contable' onChange={(e) => setAccCountFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Descripción SAP' onChange={(e) => setAccCountDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <SelectXs value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </SelectXs>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={reportCodesCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>Código de Reporte Mayor RIM</StyledTh>
              <StyledTh>Código de Reporte Detalle RIM</StyledTh>
              <StyledTh>Descripción Código de Reporte Detalle RIM</StyledTh>
              <StyledTh>Store Retail o Non Store Retail</StyledTh>
              <StyledTh>Cuenta Contable SAP</StyledTh>
              <StyledTh>Descripción Cuenta Contable SAP</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              reportCodes.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  reportCodes.map(report => (
                    <StyledTr key={report.id}>
                      <StyledTd>{report.bu}</StyledTd>
                      <StyledTd>{report.country}</StyledTd>
                      <StyledTd>{report.originData}</StyledTd>
                      <StyledTd>{report.originDetail}</StyledTd>
                      <StyledTd>{report.originDescription}</StyledTd>
                      <StyledTd>{report.retail}</StyledTd>
                      <StyledTd>{report.destinationData}</StyledTd>
                      <StyledTd>{report.destinationDescription}</StyledTd>
                      <StyledTd>{report.active === 'true' ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button onClick={() => handleModify(report.id)}>
                          <PencilIcon className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                        <button onClick={() => handleDelete(report.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
