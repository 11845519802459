/* eslint camelcase: "off" */
import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getCostCenterService,
  getCebesService,
  countCebesService,
  updateCostCenterService,
  getAllCebesService
} from '../../services/cost-center.service'

import {
  StyledCSVLink,
  Input,
  Select,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Cebe } from './interface'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { headerCsv } from './headerExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: Cebe = {
  active: '',
  bu: '',
  ceco: '',
  country: '',
  description: '',
  destinationData: '',
  id: '',
  originData: ''
}

const title = 'Tienda - Centro de Beneficio - Costo'

export const CenterCostPage = () => {
  const [cebes, setCebes] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [cebesCsv, setCebesCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [POSFilter, setPOSFilter] = useState('')
  const [CeBeSAPFilter, setCeBeSAPFilter] = useState('')
  const [CeCoSAPFilter, setCeCoSAPFilter] = useState('')
  const [descriptionFilter, setDescriptionFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })

  const navigate = useNavigate()

  const paginate = (data: any) => {
    const indexOfLastCebe = currentPage * 10
    const indexOfFirstCebe = indexOfLastCebe - 10
    const cebesPaginated = data.slice(indexOfFirstCebe, indexOfLastCebe)
    return cebesPaginated
  }

  const getCebes = async () => {
    try {
      const resp = await getAllCebesService()
      const { status, data } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        const cebesPaginated = paginate(filteredData)
        setCebes(cebesPaginated)
        setBackupObj(filteredData)
        const totalPages = Math.ceil(filteredData.length / 10)
        setFilteredTotalPages(totalPages)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      // Determinar si la simulación está habilitada
      const simulateProfile = process.env.REACT_APP_SIMULATE_PROFILE === 'true'
      const userProfile = process.env.REACT_APP_USER_PROFILE

      if (simulateProfile && userProfile) {
        const localProfile = JSON.parse(userProfile)
        setProfile(localProfile)
      } else {
        const resp = await getProfileUserService()
        const { status, data } = resp
        if (status === 200) {
          setProfile(data)
        }
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countCebes = async () => {
    try {
      const resp = await countCebesService()
      const { status, data } = resp
      if (status === 200) {
        setCebesCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countCebes()
  }, [])

  useEffect(() => {
    getCebes()
  }, [currentPage, profile])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(POSFilter.toLowerCase()))
    const cebesPaginated = paginate(filterObj)
    setCebes(cebesPaginated)
  }, [POSFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(CeBeSAPFilter.toLowerCase()))
    const cebesPaginated = paginate(filterObj)
    setCebes(cebesPaginated)
  }, [CeBeSAPFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.ceco).toLowerCase().includes(CeCoSAPFilter.toLowerCase()))
    const cebesPaginated = paginate(filterObj)
    setCebes(cebesPaginated)
  }, [CeCoSAPFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.description).toLowerCase().includes(descriptionFilter.toLowerCase()))
    const cebesPaginated = paginate(filterObj)
    setCebes(cebesPaginated)
  }, [descriptionFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    const cebesPaginated = paginate(filterObj)
    setCebes(cebesPaginated)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/cost-center-form')
  }

  const handleModify = (id: string) => {
    navigate(`/cost-center-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getCostCenterService(id)
      const { all_fields_json } = resp.data
      if (!all_fields_json.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          all_fields_json.active = false
          await updateCostCenterService(id, all_fields_json)
          Swal.fire('¡Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getCebes())
        }
      })
    } catch (error) {
      throw new Error(`error ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Código Tienda POS' onChange={(e) => setPOSFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='CeBe SAP' onChange={(e) => setCeBeSAPFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='CeCo SAP' onChange={(e) => setCeCoSAPFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Descripción' onChange={(e) => setDescriptionFilter(e.target.value)} type="text" />
            </InputContainer>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </Select>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={cebesCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>Código Tienda POS</StyledTh>
              <StyledTh>CeBe SAP</StyledTh>
              <StyledTh>CeCo SAP</StyledTh>
              <StyledTh>Descripción</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              cebes.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                  )
                : (
                    cebes.map(cebe => (
                    <StyledTr key={cebe.id}>
                      <StyledTd>{cebe.bu}</StyledTd>
                      <StyledTd>{cebe.country}</StyledTd>
                      <StyledTd>{cebe.originData}</StyledTd>
                      <StyledTd>{cebe.destinationData}</StyledTd>
                      <StyledTd>{cebe.ceco}</StyledTd>
                      <StyledTd>{cebe.description}</StyledTd>
                      <StyledTd>{cebe.active === 'true' ? 'Activo' : 'Inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button>
                          <PencilIcon
                            onClick={() => handleModify(cebe.id)}
                            className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font"
                          />
                        </button>
                        <button onClick={() => handleDelete(cebe.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                    ))
                  )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody >
    </Card>
  )
}
