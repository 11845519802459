/* eslint camelcase: "off" */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Card,
  StyledCardBody,
  StyledDiv,
  Bar,
  Button,
  ButtonSave,
  Label,
  Select,
  Input,
  DivTextStyled
} from '../../styled-components/FormStyled'
import { getReportCodeService, saveReportCodeService, updateReportCodeService } from '../../services/report-codes.service'

interface IReportCodes {
  bu: string
  country: string
  originCapacity: string
  destinationCapacity: string
  originData: string
  originDetail: string
  originDescription: string
  retail: string
  destinationData: string
  destinationDescription: string
  active: boolean
}

const initialState: IReportCodes = {
  bu: '',
  country: '',
  originCapacity: '',
  destinationCapacity: '',
  originData: '',
  originDetail: '',
  originDescription: '',
  retail: '',
  destinationData: '',
  destinationDescription: '',
  active: true
}

export const ReportCodes = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState(initialState)

  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const {
    bu,
    country,
    originCapacity,
    destinationCapacity,
    originData,
    originDetail,
    originDescription,
    retail,
    destinationData,
    destinationDescription,
    active
  } = formValues

  const getReportCode = async () => {
    try {
      const resp = await getReportCodeService(id)
      const { all_fields_json } = resp.data
      setFormValues(all_fields_json)
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    if (id) {
      getReportCode()
    }
  }, [id])

  const handleCreate = async () => {
    try {
      await saveReportCodeService(formValues)
    } catch (error) {
      Swal.fire('Error al insertar el registro')
      throw new Error(`Error al insertar el registro ${error}`)
    }
  }

  const handleModify = async () => {
    !formValues.active && (formValues.active = true)
    const reportCode = { id, ...formValues }

    try {
      await updateReportCodeService(id, reportCode)
    } catch (error) {
      Swal.fire('Error al modificar el registro')
      throw new Error(`Error al modificar el registro, ${error}`)
    }
  }

  const handleSave = () => {
    id ? handleModify() : handleCreate()

    if (!bu || !country || !originCapacity || !destinationCapacity ||
      !originData || !destinationData || !destinationDescription) {
      return Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error...',
        text: 'Todos los campos son obligatorios!'
      })
    }

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Registro ${id ? 'modificado' : 'creado'} correctamente`,
      showConfirmButton: false,
      timer: 1000
    }).then(() => navigate('/report-code'))
  }

  const handleCancel = () => {
    navigate('/report-code')
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <h2 className="text-2xl font-bold">
            {id ? 'Modificar' : 'Agregar'} Registro
          </h2>
          <form className="container mt-8" autoComplete="off">
            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">Unidad de Negocio (*)</Label>
              <Select
                name="bu"
                value={bu}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value='' disabled hidden>
                  Seleccionar
                </option>
                <option value="IKS" className="uppercase">IKS</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">País (*)</Label>
              <Select
                name="country"
                value={country}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value="" disabled hidden>
                  Seleccionar
                </option>
                <option value="CL">Chile</option>
                <option value="CO">Colombia</option>
                <option value="PE">Peru</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-capacity">Capacidad de Origen (*)</Label>
              <Input
                type="text"
                name="originCapacity"
                value={originCapacity}
                placeholder="RIM"
                id="origin-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-capacity">Capacidad de Destino (*)</Label>
              <Input
                type="text"
                name="destinationCapacity"
                value={destinationCapacity}
                placeholder="SAP"
                id="destination-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-data">Datos de Origen (*)</Label>
              <Input
                type="text"
                name="originData"
                value={originData}
                placeholder="SS"
                id="origin-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-detail">Detalle de Origen (*)</Label>
              <Input
                type="text"
                name="originDetail"
                value={originDetail}
                placeholder="01"
                id="origin-detail"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-description">Descripción de Origen</Label>
              <Input
                type="text"
                name="originDescription"
                value={originDescription}
                id="origin-description"
                placeholder="Venta Boleta"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="retail">Retail</Label>
              <Input
                type="text"
                name="retail"
                value={retail}
                id="retail"
                placeholder="SR"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-data">Datos de Destino</Label>
              <Input
                type="text"
                name="destinationData"
                value={destinationData}
                placeholder="5011110001"
                id="destination-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-desc">Descripción de Destino</Label>
              <Input
                type="text"
                name="destinationDescription"
                value={destinationDescription}
                id="destination-desc"
                placeholder="Ingreso por Venta Productos"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            {
              (id && !active) &&
              <div className="flex justify-between items-center mt-4">
                <Label htmlFor="active">Activar registro</Label>
                <Select
                  name="active"
                  value={active.toString()}
                  onChange={handleChange}
                  id="active"
                >
                  <option value="true">Activar</option>
                </Select>
              </div>
            }
          </form>
          <DivTextStyled>
            <p>
              <span>Nota:</span> los campos con (*) son obligatorios
            </p>
          </DivTextStyled>
          <Bar>
            <Button onClick={handleCancel}>Cancelar</Button>
            <ButtonSave type="submit" onClick={handleSave}>Guardar</ButtonSave>
          </Bar>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
