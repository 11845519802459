/* eslint camelcase: "off" */
import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getTaxIndicatorService,
  getAllTaxIndicatorService,
  updateTaxIndicatorService,
  getTaxIndicatorsPaginatorService,
  countCebesService
} from '../../services/tax-indicators.service'
import {
  StyledCSVLink,
  InputSm,
  SelectSm,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { TaxIndicator } from './interface'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { headerCsv } from './headerExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: TaxIndicator = {
  accountingAccount: '',
  active: '',
  bu: '',
  country: '',
  destinationData: '',
  id: '',
  originData: '',
  taxKey: '',
  taxRate: '',
  taxType: '',
  taxTypeDestinationDescription: ''
}

const title: string = 'Indicadores de Impuestos'

export const TaxIndicatorPage = () => {
  const [taxIndicators, setTaxIndicators] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [taxIndicatorsCsv, setTaxIndicatorsCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [taxTypeFilter, setTaxTypeFilter] = useState('')
  const [taxFlagFilter, setTaxFlagFilter] = useState('')
  const [taxKeyFilter, setTaxKeyFilter] = useState('')
  const [taxIndicatorFilter, setTaxIndicatorFilter] = useState('')
  const [accountingAccountFilter, setAccountingAccountFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastItem = currentPage * 10
    const indexOfFirstItem = indexOfLastItem - 10
    const dataPaginated = processData.slice(indexOfFirstItem, indexOfLastItem)
    setTaxIndicators(dataPaginated)
  }

  const getTaxIndicators = async () => {
    try {
      const resp = await getAllTaxIndicatorService()
      const { status, data } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      // Determinar si la simulación está habilitada
      const simulateProfile = process.env.REACT_APP_SIMULATE_PROFILE === 'true'
      const userProfile = process.env.REACT_APP_USER_PROFILE

      if (simulateProfile && userProfile) {
        const localProfile = JSON.parse(userProfile)
        setProfile(localProfile)
      } else {
        const resp = await getProfileUserService()
        const { status, data } = resp
        if (status === 200) {
          setProfile(data)
        }
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countCebes = async () => {
    try {
      const resp = await countCebesService()
      const { status, data } = resp
      if (status === 200) {
        setTaxIndicatorsCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countCebes()
  }, [])

  useEffect(() => {
    getTaxIndicators()
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.taxType).toLowerCase().includes(taxTypeFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [taxTypeFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(taxFlagFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [taxFlagFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.taxKey).toLowerCase().includes(taxKeyFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [taxKeyFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(taxIndicatorFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [taxIndicatorFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.accountingAccount).toLowerCase().includes(accountingAccountFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [accountingAccountFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/tax-indicator-form')
  }

  const handleModify = (id: string) => {
    navigate(`/tax-indicator-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getTaxIndicatorService(id)
      const { all_fields_json } = resp.data

      if (!all_fields_json.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          all_fields_json.active = false
          updateTaxIndicatorService(id, all_fields_json)
          Swal.fire('¡Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getTaxIndicators())
        }
      })
    } catch (error) {
      throw new Error(`error, ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Tipo de Impuesto' onChange={(e) => setTaxTypeFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Marca del Impuesto' onChange={(e) => setTaxFlagFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Clave Impuesto' onChange={(e) => setTaxKeyFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Indicador Impuesto' onChange={(e) => setTaxIndicatorFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputSm placeholder='Cuenta Contable' onChange={(e) => setAccountingAccountFilter(e.target.value)} type="text" />
            </InputContainer>
            <SelectSm value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </SelectSm>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={taxIndicatorsCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>Tipo de Impuesto</StyledTh>
              <StyledTh>Marca del Impuesto</StyledTh>
              <StyledTh>Tasa Impuesto</StyledTh>
              <StyledTh>Clave Impuesto SAP</StyledTh>
              <StyledTh>Indicador Impuesto SAP</StyledTh>
              <StyledTh>Descripción Tipo de Impuesto SAP</StyledTh>
              <StyledTh>Cuenta Contable SAP</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              taxIndicators.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  taxIndicators.map(taxIndicator => (
                    <StyledTr key={taxIndicator.id}>
                      <StyledTd>{taxIndicator.bu}</StyledTd>
                      <StyledTd>{taxIndicator.country}</StyledTd>
                      <StyledTd>{taxIndicator.taxType}</StyledTd>
                      <StyledTd>{taxIndicator.originData}</StyledTd>
                      <StyledTd>{taxIndicator.taxRate}%</StyledTd>
                      <StyledTd>{taxIndicator.taxKey}</StyledTd>
                      <StyledTd>{taxIndicator.destinationData}</StyledTd>
                      <StyledTd>{taxIndicator.taxTypeDestinationDescription}</StyledTd>
                      <StyledTd>{taxIndicator.accountingAccount}</StyledTd>
                      <StyledTd>{taxIndicator.active === 'true' ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button onClick={() => handleModify(taxIndicator.id)}>
                          <PencilIcon className="h-5 w-5 text-black-400  hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                        <button onClick={() => handleDelete(taxIndicator.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
