/* eslint camelcase: "off" */
import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getRIMService,
  getAllRIMService,
  updateRIMService,
  getRIMPaginatorService,
  countRIMService
} from '../../services/rim.service'
import {
  StyledCSVLink,
  Input,
  Select,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledFilterDivLine,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { ReportCodesRim } from './interface'
import { headerCsv } from './headerExportCsv'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: ReportCodesRim = {
  accounts: '',
  active: '',
  bu: '',
  chargeAccount: '',
  chargeOrder: '',
  country: '',
  creditAccount: '',
  creditOrder: '',
  destinationCapacity: '',
  drpt: '',
  id: '',
  mirror: '',
  mrpt: '',
  originCapacity: '',
  retail: ''
}

const title = 'RIM Códigos de Reporte - Cuentas Contables SAP'

export const ReportCodesRimPage = () => {
  const [reportCodeRim, setReportCodeRim] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [reportCodeRimCsv, setReportCodeRimCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [mrptFilter, setMrptFilter] = useState('')
  const [drptFilter, setDrptFilter] = useState('')
  const [mirrorFilter, setMirrorFilter] = useState('')
  const [accountsFilter, setAccountsFilter] = useState('')
  const [chargeAccountFilter, setChargeAccountFilter] = useState('')
  const [chargeOrderFilter, setChargeOrderFilter] = useState('')
  const [creditAccountFilter, setCreditAccountFilter] = useState('')
  const [creditOrderFilter, setCreditOrderFilter] = useState('')
  const [retailFilter, setRetailFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastItem = currentPage * 10
    const indexOfFirstItem = indexOfLastItem - 10
    const dataPaginated = processData.slice(indexOfFirstItem, indexOfLastItem)
    setReportCodeRim(dataPaginated)
  }

  const getReportCodesRIM = async () => {
    try {
      const resp = await getAllRIMService()
      const { data, status } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        filteredData.sort((a: any, b: any) => b.id - a.id)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      // Determinar si la simulación está habilitada
      const simulateProfile = process.env.REACT_APP_SIMULATE_PROFILE === 'true'
      const userProfile = process.env.REACT_APP_USER_PROFILE

      if (simulateProfile && userProfile) {
        const localProfile = JSON.parse(userProfile)
        setProfile(localProfile)
      } else {
        const resp = await getProfileUserService()
        const { status, data } = resp
        if (status === 200) {
          setProfile(data)
        }
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countRim = async () => {
    try {
      const resp = await countRIMService()
      const { status, data } = resp
      if (status === 200) {
        setReportCodeRimCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countRim()
  }, [])

  useEffect(() => {
    getReportCodesRIM()
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.mrpt).toLowerCase().includes(mrptFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [mrptFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.drpt).toLowerCase().includes(drptFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [drptFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.mirror).toLowerCase().includes(mirrorFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [mirrorFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.accounts).toLowerCase().includes(accountsFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [accountsFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.chargeAccount).toLowerCase().includes(chargeAccountFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [chargeAccountFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.chargeOrder).toLowerCase().includes(chargeOrderFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [chargeOrderFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.creditAccount).toLowerCase().includes(creditAccountFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [creditAccountFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.creditOrder).toLowerCase().includes(creditOrderFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [creditOrderFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.retail).toLowerCase().includes(retailFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [retailFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/reportcodesrim-form')
  }

  const handleModify = (id: string) => {
    navigate(`/reportcodesrim-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getRIMService(id)
      const { all_fields_json } = resp.data
      if (!all_fields_json.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          all_fields_json.active = false
          updateRIMService(id, all_fields_json)
          Swal.fire('Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getReportCodesRIM())
        }
      })
    } catch (error) {
      throw new Error(`error, ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='MRPT' onChange={(e) => setMrptFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='DRPT' onChange={(e) => setDrptFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Espejo' onChange={(e) => setMirrorFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Contabiliza' onChange={(e) => setAccountsFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Cuenta Cargo' onChange={(e) => setChargeAccountFilter(e.target.value)} type="text" />
            </InputContainer>
          </StyledFilterDiv>
          <StyledFilterDivLine>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Orden Cargo' onChange={(e) => setChargeOrderFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Cuenta Abono' onChange={(e) => setCreditAccountFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='Orden Abono' onChange={(e) => setCreditOrderFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <Input placeholder='SR o NSR' onChange={(e) => setRetailFilter(e.target.value)} type="text" />
            </InputContainer>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </Select>
          </StyledFilterDivLine>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={reportCodeRimCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>MRPT</StyledTh>
              <StyledTh>DRPT</StyledTh>
              <StyledTh>Espejo</StyledTh>
              <StyledTh>Contabiliza</StyledTh>
              <StyledTh>Cuenta Cargo</StyledTh>
              <StyledTh>Orden Cargo</StyledTh>
              <StyledTh>Cuenta Abono</StyledTh>
              <StyledTh>Orden Abono</StyledTh>
              <StyledTh>SR/NSR</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              reportCodeRim.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  reportCodeRim.map(paymethodBP => (
                    <StyledTr key={paymethodBP.id}>
                      <StyledTd>{paymethodBP.bu}</StyledTd>
                      <StyledTd>{paymethodBP.country}</StyledTd>
                      <StyledTd>{paymethodBP.mrpt}</StyledTd>
                      <StyledTd>{paymethodBP.drpt}</StyledTd>
                      <StyledTd>{paymethodBP.mirror}</StyledTd>
                      <StyledTd>{paymethodBP.accounts}</StyledTd>
                      <StyledTd>{paymethodBP.chargeAccount}</StyledTd>
                      <StyledTd>{paymethodBP.chargeOrder}</StyledTd>
                      <StyledTd>{paymethodBP.creditAccount}</StyledTd>
                      <StyledTd>{paymethodBP.creditOrder}</StyledTd>
                      <StyledTd>{paymethodBP.retail}</StyledTd>
                      <StyledTd>{paymethodBP.active === 'true' ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button>
                          <PencilIcon
                            onClick={() => handleModify(paymethodBP.id)}
                            className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font"
                          />
                        </button>
                        <button onClick={() => handleDelete(paymethodBP.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
