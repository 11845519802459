import axios from '../interceptors/axios.interceptor'

const collection = 'acc-mpn-consolidated'
const sourceTable = 'clacom'

export const getClacomService = async (id: any) => {
    const response = await axios.get(`/get-document-id?collection=${collection}&source_table=${sourceTable}&id=${id}`)
    return response
}

export const saveClacomService = async (formValues: any) => {
    try {
      await axios.post(`/post-document?collection=${collection}&source_table=${sourceTable}`, formValues)
    } catch (error) {
      console.error('Error fetching clacom data:', error)
      throw error
    }
}

export const updateClacomService = async (id: any, cebe: any) => {
  try {
    await axios.put(`/put-document-id?collection=${collection}&source_table=${sourceTable}&id=${id}`, cebe)
  } catch (error) {
    console.error('Error fetching cebes data:', error)
    throw error
  }
}

export const getClacomsPaginatorService = (currentPage: number) => {
  return axios.get(`/clacom/paginator/page?offset=${currentPage}`)
}
/* eslint-disable camelcase */ //
export const getAllClacomsService = async () => {  
    try {
      // Obtén los datos del endpoint
      const response = await axios.get(`/get-document?collection=${collection}&source_table=${sourceTable}`)
      const data = response.data
  
      // Mapea los datos a la estructura de Clacom
      const clacoms = {
        status: response.status,
        data: data.map((item: { all_fields_json: any; unique_id: any }) => {
            const { all_fields_json, unique_id } = item
      
            // Extraer `bu`, `country`, `originCapacity` y `destinationCapacity` del `unique_id`
            const uniqueIdParts = unique_id.split('-')
            const bu = uniqueIdParts[1] // Segundo segmento del unique_id
            const country = uniqueIdParts[2] // Tercer segmento del unique_id
            const originCapacity = uniqueIdParts[4] // Primer "SAP" del unique_id
            const destinationCapacity = uniqueIdParts[5] // Segundo "SAP" del unique_id
      
            return {
              active: all_fields_json.active ? 'true' : 'false', // Convertir a string
              bu,
              country,
              destinationCapacity,
              destinationData: all_fields_json.destinationData,
              destinationDescription: all_fields_json.destinationDescription,
              id: unique_id, // Convertir a string
              originCapacity,
              originData: all_fields_json.originData,
              originDescription: all_fields_json.originDescription
            }
          })
      }

      return clacoms
    } catch (error) {
      console.error('Error fetching Clacom data:', error)
      throw error
    }
  }

export const countClacomService = async () => {
    try {
      const allClacoms = await getAllClacomsService()
      return allClacoms// Devuelve un objeto con la cantidad
    } catch (error) {
      console.error('Error counting Clacom:', error)
      throw error
    }
  }
