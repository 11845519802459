/* eslint camelcase: "off" */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  Card,
  StyledCardBody,
  StyledDiv,
  Bar,
  Button,
  ButtonSave,
  Label,
  Select,
  Input,
  DivTextStyled
} from '../../styled-components/FormStyled'
import { getCostCenterService, saveCostCenterService, updateCostCenterService } from '../../services/cost-center.service'

interface IFormData {
  bu: string
  country: string
  originCapacity: string
  destinationCapacity: string
  originData: string
  destinationData: string
  description: string
  active: boolean
  ceco: string
}

const initialState: IFormData = {
  bu: '',
  country: '',
  originCapacity: '',
  destinationCapacity: '',
  originData: '',
  destinationData: '',
  description: '',
  active: true,
  ceco: ''
}

export const CostCenter = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState(initialState)

  const handleChange = (e: any) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }

  const {
    bu,
    country,
    originCapacity,
    destinationCapacity,
    originData,
    destinationData,
    description,
    active,
    ceco
  } = formValues

  const getCostCenter = async () => {
    try {
      const resp = await getCostCenterService(id)
      const { all_fields_json } = resp.data
      setFormValues(all_fields_json)
    } catch (error) {
      throw new Error(`Error al listar los registros ${error}`)
    }
  }

  useEffect(() => {
    if (id) {
      getCostCenter()
    }
  }, [id])

  const handleCreate = async () => {
    try {
    console.log('formValues', formValues)
      await saveCostCenterService(formValues)
    } catch (error) {
      Swal.fire('Error al insertar el registro')
      throw new Error(`Error al insertar el registro ${error}`)
    }
  }

  const handleModify = async () => {
    !formValues.active && (formValues.active = true)
    const cebe = { id, ...formValues }

    try {
      await updateCostCenterService(id, cebe)
    } catch (error) {
      Swal.fire('Error al modificar el registro')
      throw new Error(`Error al modificar el registro, ${error}`)
    }
  }

  const handleSave = () => {
    id ? handleModify() : handleCreate()

    if (!bu || !country || !originCapacity || !destinationCapacity ||
      !originData || !destinationData || !description ||
      !ceco) {
      return Swal.fire({
        icon: 'error',
        title: 'Ocurrio un error...',
        text: 'Todos los campos son obligatorios!'
      })
    }

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Registro ${id ? 'modificado' : 'creado'} correctamente`,
      showConfirmButton: false,
      timer: 1000
    }).then(() => navigate('/cost-center'))
  }

  const handleCancel = () => {
    navigate('/cost-center')
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <h2 className="text-2xl font-bold">
            {id ? 'Modificar' : 'Agregar'} Registro
          </h2>
          <form className="container mt-8" autoComplete="off">
            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">Unidad de negocio (*)</Label>
              <Select
                name="bu"
                value={bu}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value='' disabled hidden>
                  Seleccionar
                </option>
                <option value="IKS" className="uppercase">IKS</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="type-user">País (*)</Label>
              <Select
                name="country"
                value={country}
                onChange={handleChange}
                id="type-user"
                disabled={!active}
              >
                <option value="" disabled hidden>
                  Seleccionar
                </option>
                <option value="CL">Chile</option>
                <option value="CO">Colombia</option>
                <option value="PE">Peru</option>
              </Select>
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-capacity">Capacidad de origen (*)</Label>
              <Input
                type="text"
                name="originCapacity"
                value={originCapacity}
                placeholder="POS"
                id="origin-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-capacity">Capacidad de destino (*)</Label>
              <Input
                type="text"
                name="destinationCapacity"
                value={destinationCapacity}
                placeholder="SAP"
                id="destination-capacity"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="origin-data">Datos de origen (*)</Label>
              <Input
                type="text"
                name="originData"
                value={originData}
                placeholder="594"
                id="origin-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="destination-data">Datos de destino (*)</Label>
              <Input
                type="text"
                name="destinationData"
                value={destinationData}
                placeholder="C107159401"
                id="destination-data"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="description">Descripción</Label>
              <Input
                className="border border-gray-400 rounded-lg px-2 py-1 focus:outline-dotted focus:outline-1 w-2/4"
                type="text"
                name="description"
                value={description}
                id="description"
                placeholder="IKEA Open Kennedy"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <Label htmlFor="ceco">CeCo (*)</Label>
              <Input
                className="border border-gray-400 rounded-lg px-2 py-1 focus:outline-dotted focus:outline-1 w-2/4"
                type="text"
                name="ceco"
                value={ceco}
                id="ceco"
                placeholder="C107159401"
                onChange={handleChange}
                disabled={!active}
              />
            </div>

            {
              (id && !active) &&
              <div className="flex justify-between items-center mt-4">
                <Label htmlFor="active">Activar registro</Label>
                <Select
                  name="active"
                  value={active.toString()}
                  onChange={handleChange}
                  id="active"
                  className="border bg-white border-gray-400 rounded-lg px-2 py-1 focus:outline-dotted focus:outline-1 w-2/4 capitalize"
                >
                  <option value="true">Activar</option>
                </Select>
              </div>
            }

          </form>
          <DivTextStyled>
            <p>
              <span>Nota:</span> los campos con (*) son obligatorios
            </p>
          </DivTextStyled>
          <Bar>
            <Button
              onClick={handleCancel}
              className="mt-8 transition capitalize px-4 py-2 bg-red-500 rounded-md text-white hover:bg-red-600 active:bg-red-800"
            >
              cancelar
            </Button>

            <ButtonSave
              type="submit"
              className="mt-8 transition capitalize px-4 py-2 bg-blue-500 rounded-md text-white hover:bg-blue-600 active:bg-blue-800"
              onClick={handleSave}
            >
              Guardar
            </ButtonSave>
          </Bar>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
