/* eslint camelcase: "off" */
import React, { useEffect, useState } from 'react'
import {
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  SearchIcon,
  TrashIcon
} from '@heroicons/react/solid'
import {
  getSocietyService,
  getAllSocietyService,
  updateSocietyService,
  getSocietiesPaginatorService,
  countSocietiesService
} from '../../services/societies.service'
import {
  StyledCSVLink,
  Button,
  Option,
  InputContainer,
  IconContainer,
  IconContainerBtn,
  Card,
  StyledCardBody,
  StyledDiv,
  StyledFilterDiv,
  StyledContainerDiv,
  StyledTh,
  StyledTd,
  StyledTr,
  InputXs,
  SelectXs
} from '../../styled-components/PageStyled'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Societies } from './interface'
import { prepareToExportCsv } from '../utils/prepareExportCsv'
import { headerCsv } from './headerExportCsv'
import { handleDownload } from '../utils/messageDownload'
import { Pagination } from '../../components/Pagination/Pagination'
import { getProfileUserService } from '../../services/catalog.service'
import { filterUserProfile } from '../utils/filterUserProfile'

const initialState: Societies = {
  active: '',
  bu: '',
  country: '',
  currency: '',
  description: '',
  destinationCapacity: '',
  destinationData: '',
  id: '',
  originCapacity: '',
  originData: '',
  typeNIF: ''
}

const title: string = 'Sociedades SAP'

export const SocietiesSapPage = () => {
  const [societies, setSocieties] = useState([initialState])
  const [backupObj, setBackupObj] = useState([initialState])
  const [processData, setProcessData] = useState([initialState])
  const [societiesCsv, setSocietiesCsv] = useState([initialState])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredTotalPages, setFilteredTotalPages] = useState(0)
  const [currencyFilter, setCurrencyFilter] = useState('')
  const [typeNifFilter, setTypeNifFilter] = useState('')
  const [nifFilter, setNifFilter] = useState('')
  const [capacityFilter, setCapacityFilter] = useState('')
  const [societyFilter, setSocietyFilter] = useState('')
  const [descFilter, setDescFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('activo')
  const [profile, setProfile] = useState({ id: '', country: '', bu: '', profile: '', status: '' })
  const navigate = useNavigate()

  const paginate = () => {
    // se calcula el index del ultimo elemento de la pagina
    const indexOfLastItem = currentPage * 10
    const indexOfFirstItem = indexOfLastItem - 10
    const dataPaginated = processData.slice(indexOfFirstItem, indexOfLastItem)
    setSocieties(dataPaginated)
  }

  const getSocieties = async () => {
    try {
      const resp = await getAllSocietyService()
      const { data, status } = resp
      if (status === 200) {
        const filteredData = filterUserProfile(data, profile)
        setProcessData(filteredData)
        setBackupObj(filteredData)
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  const getProfileUser = async () => {
    try {
      // Determinar si la simulación está habilitada
      const simulateProfile = process.env.REACT_APP_SIMULATE_PROFILE === 'true'
      const userProfile = process.env.REACT_APP_USER_PROFILE

      if (simulateProfile && userProfile) {
        const localProfile = JSON.parse(userProfile)
        setProfile(localProfile)
      } else {
        const resp = await getProfileUserService()
        const { status, data } = resp
        if (status === 200) {
          setProfile(data)
        }
      }
    } catch (error) {
      throw new Error(`Error al obtener el perfil del usuario, ${error}`)
    }
  }

  const countSocieties = async () => {
    try {
      const resp = await countSocietiesService()
      const { status, data } = resp
      if (status === 200) {
        setSocietiesCsv(prepareToExportCsv(data))
      }
    } catch (error) {
      throw new Error(`Error al listar los registros, ${error}`)
    }
  }

  useEffect(() => {
    getProfileUser()
    countSocieties()
  }, [])

  useEffect(() => {
    getSocieties()
  }, [profile])

  useEffect(() => {
    paginate()
  }, [processData, currentPage])

  useEffect(() => {
    const totalPages = Math.ceil(processData.length / 10)
    setFilteredTotalPages(totalPages)
    setCurrentPage(1)
  }, [processData])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.currency).toLowerCase().includes(currencyFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [currencyFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.typeNIF).toLowerCase().includes(typeNifFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [typeNifFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originData).toLowerCase().includes(nifFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [nifFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.originCapacity).toLowerCase().includes(capacityFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [capacityFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.destinationData).toLowerCase().includes(societyFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [societyFilter])

  useEffect(() => {
    const filterObj = backupObj.filter((item) => (item.description).toLowerCase().includes(descFilter.toLowerCase()))
    setProcessData(filterObj)
  }, [descFilter])

  useEffect(() => {
    let filterObj: any = []
    if (statusFilter === 'activo') {
      filterObj = backupObj.filter((item) => Boolean(item.active) === true)
    } else {
      filterObj = backupObj.filter((item) => Boolean(item.active) === false)
    }
    setProcessData(filterObj)
  }, [statusFilter])

  const handleAdd = () => {
    navigate('/societies-form')
  }

  const handleModify = (id: string) => {
    navigate(`/societies-form/${id}`)
  }

  const handleDelete = async (id: string) => {
    try {
      const resp = await getSocietyService(id)
      const { all_fields_json } = resp.data

      if (!all_fields_json.active) {
        Swal.fire('El registro ya esta desactivado')
        return
      }

      Swal.fire({
        title: '¡Cuidado!',
        text: '¡Esto desactivara el registro! para activarlo deberás ingresar al formulario.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar'
      }).then((result) => {
        if (result.isConfirmed) {
          all_fields_json.active = false
          updateSocietyService(id, all_fields_json)
          Swal.fire('Eliminado!', 'El registro se ha desactivado.', 'success')
            .then(() => getSocieties())
        }
      })
    } catch (error) {
      throw new Error(`error, ${error}`)
    }
  }

  return (
    <Card>
      <StyledCardBody className="animate__animated animate__fadeIn">
        <StyledDiv>
          <StyledFilterDiv>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Moneda' onChange={(e) => setCurrencyFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Tipo NIF' onChange={(e) => setTypeNifFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='NIF' onChange={(e) => setNifFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Capacidad Origen' onChange={(e) => setCapacityFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Sociedad SAP' onChange={(e) => setSocietyFilter(e.target.value)} type="text" />
            </InputContainer>
            <InputContainer>
              <IconContainer>
                <SearchIcon />
              </IconContainer>
              <InputXs placeholder='Descripción' onChange={(e) => setDescFilter(e.target.value)} type="text" />
            </InputContainer>
            <SelectXs value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <Option value='activo'>Activo</Option>
              <Option value='inactivo'>Inactivo</Option>
            </SelectXs>
          </StyledFilterDiv>
          <StyledContainerDiv>
            <Button onClick={handleAdd}>
              <IconContainerBtn>
                <PlusIcon />
              </IconContainerBtn>
              Agregar
            </Button>
            <StyledCSVLink
              onClick={handleDownload}
              filename={title}
              headers={headerCsv}
              data={societiesCsv}>
              <IconContainerBtn>
                <DownloadIcon />
              </IconContainerBtn>
              Exportar Catálogo
            </StyledCSVLink>
          </StyledContainerDiv>
          <table>
            <tr>
              <StyledTh>Unidad de Negocio</StyledTh>
              <StyledTh>País</StyledTh>
              <StyledTh>Moneda</StyledTh>
              <StyledTh>Tipo NIF</StyledTh>
              <StyledTh>NIF</StyledTh>
              <StyledTh>Capacidad Origen</StyledTh>
              <StyledTh>Sociedad SAP</StyledTh>
              <StyledTh>Descripción</StyledTh>
              <StyledTh>Estado</StyledTh>
              <StyledTh>Acciones</StyledTh>
            </tr>
            {
              societies.length === 0
                ? (
                  <tr>
                    <td colSpan={8} className="text-center pt-6" >No se encontraron registros asociados a tu perfil.</td>
                  </tr>
                )
                : (
                  societies.map(society => (
                    <StyledTr key={society.id}>
                      <StyledTd>{society.bu}</StyledTd>
                      <StyledTd>{society.country}</StyledTd>
                      <StyledTd>{society.currency}</StyledTd>
                      <StyledTd>{society.typeNIF}</StyledTd>
                      <StyledTd>{society.originData}</StyledTd>
                      <StyledTd>{society.originCapacity}</StyledTd>
                      <StyledTd>{society.destinationData}</StyledTd>
                      <StyledTd>{society.description}</StyledTd>
                      <StyledTd>{society.active === 'true' ? 'activo' : 'inactivo'}</StyledTd>
                      <StyledTd className="flex">
                        <button onClick={() => handleModify(society.id)}>
                          <PencilIcon className="h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                        <button onClick={() => handleDelete(society.id)}>
                          <TrashIcon className="ml-5 h-5 w-5 text-black-400 hover:text-black-500 active:text-brand_menu_font" />
                        </button>
                      </StyledTd>
                    </StyledTr>
                  ))
                )
            }
          </table>
          <div className="my-4">
            {filteredTotalPages > 0 && <Pagination totalRecords={filteredTotalPages} current={currentPage} setCurrent={setCurrentPage} />}
          </div>
        </StyledDiv>
      </StyledCardBody>
    </Card>
  )
}
