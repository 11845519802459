import axios from '../interceptors/axios.interceptor'
const collection = 'acc-mpn-consolidated'
const sourceTable = 'paymethods'

export const getPayMethodService = async (id: any) => {
    const response = await axios.get(`/get-document-id?collection=${collection}&source_table=${sourceTable}&id=${id}`)
    return response
}

export const savePayMethodService = async (formValues: any) => {
    try {
      await axios.post(`/post-document?collection=${collection}&source_table=${sourceTable}`, formValues)
    } catch (error) {
      console.error('Error fetching paymethods data:', error)
      throw error
    }
}

export const updatePayMethodService = async (id: any, taxIndicator: any) => {
    try {
        await axios.put(`/put-document-id?collection=${collection}&source_table=${sourceTable}&id=${id}`, taxIndicator)
    } catch (error) {
        console.error('Error fetching cebes data:', error)
        throw error
    }
}

export const getPayMethodsPaginatorService = (currentPage: any) => {
  return axios.get(`/pay-methods/paginator/page?offset=${currentPage}`)
}

/* eslint-disable camelcase */ //
export const getAllPayMethodService = async () => {
   try {
    // Obtén los datos del endpoint
    const response = await axios.get(`/get-document?collection=${collection}&source_table=${sourceTable}`)
    const data = response.data

    // Mapea los datos a la estructura de Clacom
    const paymethod = {
      status: response.status,
      data: data.map((item: { all_fields_json: any; unique_id: any }) => {
          const { all_fields_json, unique_id } = item
    
          // Extraer `bu`, `country`, `originCapacity` y `destinationCapacity` del `unique_id`
          const uniqueIdParts = unique_id.split('-')
          const bu = uniqueIdParts[1] // Segundo segmento del unique_id
          const country = uniqueIdParts[2] // Tercer segmento del unique_id
          // const originCapacity = uniqueIdParts[4] // Primer "SAP" del unique_id
         //  const destinationCapacity = uniqueIdParts[5] // Segundo "SAP" del unique_id
    
          return {
            active: all_fields_json.active ? 'true' : 'false', // Convertir a string
            bu,
            country,
            destinationData: all_fields_json.destinationData,
            destinationDescription: all_fields_json.destinationDescription,
            id: unique_id, // Convertir a string
            originData: all_fields_json.originData,
            originDescription: all_fields_json.originDescription,
            scc: all_fields_json.scc
          }
        })
    }

    return paymethod
  } catch (error) {
    console.error('Error fetching paymethod data:', error)
    throw error
  }
}

export const countPayMethodsService = async () => {
    try {
        // Llamamos al servicio getAllPayMethodsBPService para obtener los datos
        const paymethod = await getAllPayMethodService()
    
        // Simulamos la respuesta del endpoint /clacom
        return paymethod// Devuelve un objeto con la cantidad
      } catch (error) {
        console.error('Error counting paymeth:', error)
        throw error
      }
}
